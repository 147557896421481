"use client";

import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import { usePathname, useSelectedLayoutSegments } from "next/navigation";
import { useEffect, useState } from "react";
import { IsAdmin } from "@/utils/common/admin";
import { MessagingVerificationStatusResponse } from "@/types/messaging";
import { apiLive } from "@/utils/common/api";
import { MessagingVerificationStatus } from "@/enums/messaging";
import { ClearAllCache } from "@/utils/common/cache";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import { useProfile } from "@/hooks/useProfile";
import { GetBrand } from "@/utils/common/theme";
import { BRANDS } from "@/enums/brands";
import { UserRoles } from "@/enums/user";
import { GlobalNavSectionType } from "@/types/common/library";
import NavLayout from "../library/NavLayout";
import clsx from "clsx";
import { Icon, ICON_SIZES } from "../library/Icon";

const IsActive = (segments: string[], target: string, position: number = 0) => {
  return segments.length > position && segments[position] === target;
};

export default function NavContainer({
  children,
  setShowUserManagement,
}: {
  children: React.ReactNode;
  setShowUserManagement: (value: boolean) => void;
}) {
  const { t } = useTranslation("routes");
  const { language } = useProfile();

  const { getToken, user, logout, isFreeTrial, subscription, role } =
    useAuthContext();
  const pathname = usePathname();
  const segments = useSelectedLayoutSegments();

  const [sections, setSections] = useState<GlobalNavSectionType[]>([]);
  const [isMessagingVerified, setIsMessagingVerified] =
    useState<boolean>(false);
  const [brand, setBrand] = useState<BRANDS>(BRANDS.GAIL);

  const userActions = {
    onLogoutClick: () => {
      ClearAllCache();
      logout();
    },
    onUserManagementClick: () => {
      setShowUserManagement(true);
    },
  };

  const fetchMessagingStatus = async () => {
    const messagingStatusResponse =
      await apiLive<MessagingVerificationStatusResponse>(
        getToken,
        "/api/settings/agency/messaging-verification"
      );

    if (messagingStatusResponse) {
      setIsMessagingVerified(
        messagingStatusResponse.status ===
          MessagingVerificationStatus.CampaignApproved
      );
    }
  };

  useEffect(() => {
    fetchMessagingStatus();
    setBrand(GetBrand(window.location.host));
  }, []);

  useEffect(() => {
    let main: GlobalNavSectionType = {
      name: t("main"),
      items: [
        /*{
          icon: "gauge-simple-high",
          label: ROUTE_LABELS.DASHBOARD,
          href: "/",
          isActive: segments.length === 0,
        },*/
        {
          icon: "phone",
          label: t("calls"),
          href: "/calls",
          isActive: IsActive(segments, "calls"),
        },
        {
          icon: "address-card",
          label: t("contacts"),
          href: "/contacts",
          isActive: IsActive(segments, "contacts"),
        },
        {
          icon: "megaphone",
          label: t("campaigns"),
          href: "/campaigns",
          isActive: IsActive(segments, "campaigns"),
        },
      ],
    };

    let resources: GlobalNavSectionType = {
      name: t("resources"),
      position: "bottom",
      items: [
        {
          icon: "lightbulb-on",
          label: t("features"),
          href: "https://updates.lula.com/changelog",
          target: "_blank",
          dataAttributes: {
            "data-canny-link": "true",
          },
        },
        {
          icon: "headset",
          label: t("support"),
          href: "https://support.lula.com",
          target: "_blank",
        },
      ],
    };

    let customizations: GlobalNavSectionType = {
      name: t("customizations"),
      items:
        role === UserRoles.User
          ? []
          : [
              {
                icon: "scroll",
                label: t("scripts"),
                href: "/scripts",
                isActive: IsActive(segments, "scripts"),
              },
              {
                icon: "bell-concierge",
                label: t("receptionist"),
                href: "/receptionist",
                isActive: IsActive(segments, "receptionist"),
              },
              {
                icon: "handshake",
                label: t("partners"),
                href: "/partners",
                isActive: IsActive(segments, "partners"),
              },
              {
                icon: "gear",
                label: t("settings"),
                href: "/settings",
                isActive: IsActive(segments, "settings"),
              },
            ],
    };

    if (isMessagingVerified) {
      main.items.splice(1, 0, {
        icon: "message-sms",
        label: t("messages"),
        href: "/messages",
        isActive: IsActive(segments, "messages"),
      });
    }

    if (IsAdmin(user)) {
      customizations.items.push({
        icon: "user-crown",
        label: t("admin"),
        href: "/admin",
        isActive: IsActive(segments, "admin"),
      });
    }

    if (
      resources.items.findIndex((item) => item.href === "/try-gail") < 0 &&
      isFreeTrial
    ) {
      resources.items = [
        {
          icon: "rocket",
          label: t("tryGail"),
          href: "/try-gail",
          isActive: pathname.startsWith("/try-gail"),
        },
        ...resources.items,
      ];
    } else if (
      !isFreeTrial &&
      resources.items.findIndex((item) => item.href === "/start") < 0 &&
      !subscription.hasUsage
    ) {
      resources.items = [
        {
          icon: "rocket",
          label: t("start"),
          href: "/start",
          isActive: pathname.startsWith("/start"),
        },
        ...resources.items,
      ];
    }

    let newSections: GlobalNavSectionType[] = [main];

    if (customizations.items.length > 0) {
      newSections.push(customizations);
    }

    newSections.push(resources);

    setSections(newSections);
  }, [
    pathname,
    user,
    isFreeTrial,
    isMessagingVerified,
    subscription,
    language,
    role,
  ]);

  return (
    <>
      {!!user && (
        <NavLayout
          customLogo={
            <div
              className={clsx("grid", "gap-2", "items-center", {
                "grid-cols-1": brand === BRANDS.GAIL,
                "grid-cols-[1fr_20px_1fr]": brand !== BRANDS.GAIL,
              })}
            >
              <div className="flex items-center justify-center">
                <Image
                  src="/icons/gail.svg"
                  alt="GAIL Logo"
                  width={160}
                  height={63}
                  priority={true}
                />
              </div>
              {brand !== BRANDS.GAIL && (
                <span className="text-center">
                  <Icon icon="x" size={ICON_SIZES.SM} />
                </span>
              )}
              {brand === BRANDS.DESOL && (
                <div className="flex items-center justify-center">
                  <Image
                    src="/icons/desol.svg"
                    alt="DESOL Logo"
                    width={160}
                    height={63}
                    priority={true}
                  />
                </div>
              )}
            </div>
          }
          menuSections={sections}
          user={user}
          userActions={userActions}
        >
          {children}
        </NavLayout>
      )}
      {!user && <>{children}</>}
    </>
  );
}
