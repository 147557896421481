import * as React from "react";
import clsx from "clsx";
import NavLayoutSidebar from "./NavLayoutSidebar";
import NavLayoutHeader from "./NavLayoutHeader";
import { CSSTransition } from "react-transition-group";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import Backdrop from "./Backdrop";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { GlobalNavSectionType } from "@/types/common/library";
import { User } from "@/contexts/common/AuthContextProvider";
import useIsMobile from "@/hooks/useIsMobile";

export type UserAction = {
  label: string;
  icon: IconName;
  href: string;
};

export type NayLayoutProps = {
  className?: string;
  sideBarClassName?: string;
  mainClassName?: string;
  menuSections?: GlobalNavSectionType[];
  user?: User;
  userActions?: {
    onLogoutClick?: () => void;
    onSettingsClick?: () => void;
    onUserManagementClick?: () => void;
  };
  children?: React.ReactNode;
  customLogo?: React.ReactNode;
};

const NavLayout: React.FC<NayLayoutProps> = ({
  className,
  sideBarClassName,
  mainClassName,
  menuSections = [],
  user,
  children,
  userActions = {},
  customLogo,
}) => {
  const isMobile = useIsMobile();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const sidebarRef = React.useRef<HTMLElement>(null);
  const backdropRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (isMobile) {
      if (isMobileMenuOpen) {
        if (
          sidebarRef.current?.id &&
          document &&
          document.querySelector(`#${sidebarRef.current.id}`)
        ) {
          disableBodyScroll(
            document.querySelector(`#${sidebarRef.current.id}`) as HTMLElement
          );
        }
      } else {
        clearAllBodyScrollLocks();
      }
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isMobileMenuOpen, isMobile]);

  const handleToggleMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  return (
    <div
      data-testid="nayLayout_root"
      className={clsx(
        "grid",
        "min-h-screen",
        "relative",
        "overflow-hidden",
        "pt-16",
        "md:pt-0",
        "md:grid-cols-[17rem_1fr]",
        "md:overflow-visible",
        "md:gap-4",
        className
      )}
    >
      <NavLayoutSidebar
        {...{
          menuSections,
          user,
          isMobileMenuOpen,
          sidebarRef,
          isMobile,
          userActions,
          className: sideBarClassName,
          customLogo,
        }}
      />
      <main data-testid="nayLayout_main" className={clsx(mainClassName)}>
        {isMobile && (
          <NavLayoutHeader
            onToggleMenu={handleToggleMenu}
            customLogo={customLogo}
          />
        )}

        {children}
      </main>
      <CSSTransition
        in={isMobileMenuOpen}
        nodeRef={backdropRef}
        timeout={300}
        unmountOnExit
      >
        <Backdrop onClick={() => setIsMobileMenuOpen(false)} />
      </CSSTransition>
    </div>
  );
};

export default NavLayout;
